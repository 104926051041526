<template>
  <div class="container">
    <Navbar />
    <router-view/>
    <div style="margin-top:20%;">
      <Footer />
    </div>
  </div>
</template>
<script>
import Navbar from './components/NavBar'
import Footer from './components/FootEr'

export default {
  components: {Navbar, Footer},
}
</script>
<style>
.container {
  padding: 5%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
* {box-sizing: border-box;}

body { 
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
#backUA, .backUA{
  background: linear-gradient(
    to bottom,
    #0057b7 50%, #ffd700 50%
  );
}
</style>
