<template>
    <footer>
        <div class="item1">
            <p>Federation of IPUMON 2022 &copy;</p>
        </div>
    </footer>
</template>
<style>
/* footer div p {
    float: left;
} */
footer{
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 5%;
  background:rgb(121, 123, 126);
}

.item1 {
  grid-area: note;
}
.item1-a {
  grid-area: ver;
}
.item2 {
  grid-area: link1;
}
.item3 {
  grid-area: link2;
}
.item3 {
  grid-area: link3;
}
.item1 p, .item1-a p{
  float: right;
  color: black;
  font-size: 20px;
}
footer {
  display: grid;
  background-color: #999;
  grid-template-areas: 
  'link1 link2 link3 note'
  'link5 link6 link7 ver';
  padding: 5%;
  width: 100%;
  
}
ul {
  list-style-type: none;
  margin-right: 0;
  overflow: hidden;
  font-size: 20px;
}
li a {
  text-decoration: none;
  color: black;
}
</style>