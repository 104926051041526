<template>
  <main>
    <div class="module">
      <h1>IPUMON is Coming Soon</h1>
      <h4>IPUMON is the Independent Project Universe and the Media Originators Network.</h4>
      <h4>Hope to see you in 2023.</h4>
      <h4><a href="help@ipumon.com">Questions email us at help@ipumon.com</a></h4>
    </div>
   <div class="module" id="backUA">
    <a id="UA"></a>
    <h1>Important Update in the world.</h1>
    <h4>Humanitarian crisis in Ukraine.</h4>
    <p>Ever since 2014 with the invasion and annexation of Crimea, 
      Ukrainian people have been fighting their homeland. It's been six months 
      since a major invasion attempt of Putin's russians. 
      This war thought to end in a matter of days has horrifically destroyed Ukrainians country, but not the people.
      A war of this scale in Europe has not been seen since World War II in 1939 when 
      Germany invaded Poland.</p>
      <p>The war is going in Ukraine's favour, but still poses a huge humanitarian crisis.
        For the Ukrainians and the world, Please consider donating today. 
        -The Federation of IPUMON and its members.
      </p>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  mounted() {
    document.title = 'coming soon'
  },
  components: {
    HelloWorld
  }
}
</script>
<style>
h1 {
  font-size: 48px;
}
h4 {
  font-size: 20px;
}
.module {
  border: 5px solid black;
  margin: 0;
  padding: 5%;
  margin-top: 5%;
}
</style>